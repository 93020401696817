/** * @vue-component Questions * @description Editor de questões para atividades e exames, permitindo criar diferentes
tipos de questões * @path /sparkmembers/views/Contents/.../ActivityEditor/Questions * @prefix
sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor * @features * -
Questões de múltipla escolha * - Questões associativas * - Questões verdadeiro/falso * - Pontuação configurável * -
Comentários por questão * @see Translations {@link src/locales/pt-BR.json} sparkmembers.contents.views.editor * @see
Service {@link src/services/ActivityService.js} * @see Tests {@link
src/sparkmembers/views/Contents/.../Questions/index.spec.js} */
<template>
  <div>
    <div>
      <div id="questions" class="my-3">
        <p class="d-inline font-weight-bold">
          {{ $t(`${prefix}.questions.title`) }}
        </p>
      </div>
      <div v-for="(question, questionIndex) in activity.exam_questions" :key="question.id">
        <div class="border shadow-sm rounded-lg p-3 mb-3">
          <div
            :id="`exam-edition-${questionIndex}`"
            class="d-flex justify-content-between align-items-center"
            @click="question.visible = !question.visible"
          >
            <div>
              <hs-icon variant="light" icon="bars" class="ml-3" :size="16" />
              <p class="d-inline font-weight-bold m-0 ml-3">
                {{ `${$t(`${prefix}.questions.title-only`)} ${questionIndex + 1}`
                }}<span v-if="question.type" class="sub-title-question font-weight-light">
                  ({{ customLabel(question.type) }})</span
                >
              </p>
            </div>

            <div>
              <hs-icon
                variant="light"
                :icon="question.visible ? 'chevron-up' : 'chevron-down'"
                class="ml-3"
                :size="16"
              />
              <hs-button
                @click="deleteQuestion(question)"
                icon="trash-alt"
                id="delete-question-button"
                class="ml-3 trash-icon"
                variant="transparent"
              ></hs-button>
            </div>
          </div>

          <b-collapse class="p-3" v-model="question.visible">
            <hs-group label-for="title" label-class="font-weight-bold" :label="$t(`${prefix}.questions.format.label`)">
              <MXMultiSelect
                :searchable="false"
                :custom-label="customLabel"
                :showLabels="false"
                v-model="question.type"
                @select="addAnswer(questionIndex, $event, 'create')"
                :disabled="!!question.id"
                :options="questionTypes"
              />
            </hs-group>

            <div v-if="question.type">
              <hs-group
                label-for="title"
                label-class="font-weight-bold"
                :label="$t(`${prefix}.questions.format.title`)"
              >
                <Editor v-model="question.title" :initial-value="question.title || ''" :init="editorDefaultInit" />
              </hs-group>

              <div class="mb-3" v-if="question.type === 'TrueOrFalseExamQuestion'">
                <p class="font-weight-bold mb-3">
                  {{ $t(`${prefix}.questions.answer`) }}
                </p>
                <div v-for="(option, index) in question.multiple_choice_options" :key="index">
                  <div class="d-flex flex-column justify-content-start align-items-start" :key="componentKey">
                    <RadioInputBox
                      :hideBox="true"
                      @input="forceRerender"
                      :value="true"
                      v-model="option.correct"
                      :name="`true-false-${question.id || questionIndex}`"
                    >
                      {{ $t(`${prefix}.questions.options.true`) }}
                    </RadioInputBox>
                    <RadioInputBox
                      :hide-box="true"
                      @input="forceRerender"
                      :value="false"
                      v-model="option.correct"
                      :name="`true-false-${question.id || questionIndex}`"
                    >
                      {{ $t(`${prefix}.questions.options.false`) }}
                    </RadioInputBox>
                  </div>
                </div>
              </div>

              <div class="mb-3" v-if="question.type === 'MultipleChoiceExamQuestion'" :key="componentKey">
                <p class="font-weight-bold mb-3">
                  {{ $t(`${prefix}.questions.answer`) }}
                </p>
                <hs-alert
                  class="mt-2 d-flex justify-content-start align-items-center"
                  variant="primary"
                  fade
                  :show="true"
                  dismissible
                >
                  <span>{{ $t(`${prefix}.questions.description`) }}</span>
                </hs-alert>
                <div v-for="(option, index) in question.multiple_choice_options" :key="index">
                  <div class="d-flex justify-content-between align-items-center mb-3" v-if="option">
                    <RadioInputBox
                      :hide-box="true"
                      :value="index"
                      v-model="question.correct"
                      :name="`multiple-choice-${question.id || questionIndex}`"
                    />

                    <MInput
                      v-model="option.title"
                      :placeholder="$t(`${prefix}.questions.multiple_choice_options.placeholder`)"
                      class="w-100"
                    />

                    <hs-button
                      @click="deleteAnswer(question, option)"
                      icon="trash-alt"
                      id="delete-answer-button"
                      class="ml-3 trash-icon"
                      variant="transparent"
                    ></hs-button>
                  </div>
                </div>

                <MXButton
                  @click="addAnswer(questionIndex, 'MultipleChoiceExamQuestion')"
                  icon="far fa-plus"
                  id="add-answer-button"
                  variant="tertiary"
                  >{{ $t(`${prefix}.questions.add`) }}</MXButton
                >
              </div>

              <div class="mb-3" v-if="question.type === 'AssociativeExamQuestion'" :key="componentKey">
                <hs-alert
                  class="mt-2 d-flex justify-content-start align-items-center"
                  variant="primary"
                  fade
                  v-model="infoAlert"
                  :show="infoAlert"
                  dismissible
                >
                  <span>{{ $t(`${prefix}.questions.associative_exam_question.alert`) }}</span>
                </hs-alert>
                <div v-for="(option, index) in question.multiple_choice_options" class="border mb-3 p-3" :key="index">
                  <div>
                    <div class="d-flex mb-3 justify-content-between align-items-center">
                      <p class="d-inline font-weight-bold">
                        {{ $t(`${prefix}.questions.multiple_choice_options.title`) }}
                        {{ index + 1 }}
                      </p>
                      <hs-button
                        @click="deleteAnswer(question, option)"
                        icon="trash-alt"
                        class="ml-3 trash-icon"
                        variant="transparent"
                      ></hs-button>
                    </div>
                    <b-form-textarea
                      class="mb-3"
                      :placeholder="
                        $t(`${prefix}.questions.multiple_choice_options.associative_exam_question.placeholder`)
                      "
                      rows="3"
                      spellcheck="false"
                      max-rows="4"
                      v-model="option.first_assertion_attributes.text"
                    />
                    <b-form-textarea
                      :placeholder="
                        $t(`${prefix}.questions.multiple_choice_options.associative_exam_question.placeholder2`)
                      "
                      rows="3"
                      spellcheck="false"
                      max-rows="4"
                      v-model="option.second_assertion_attributes.text"
                    />
                  </div>
                </div>

                <MXButton
                  @click="addAnswer(questionIndex, 'AssociativeExamQuestion')"
                  icon="far fa-plus"
                  id="add-answer-button"
                  variant="tertiary"
                  >{{ $t(`${prefix}.questions.add`) }}</MXButton
                >
              </div>

              <hs-group label-class="font-weight-bold" label="Pontuação">
                <MInput
                  v-model.number="question.point"
                  :placeholder="$t(`${prefix}.questions.pontuation.placeholder`)"
                  no-wheel
                  type="number"
                />
              </hs-group>

              <MSwitch
                v-model="question.isEnableComment"
                :labelTitle="$t(`${prefix}.questions.comments.title`)"
                class="d-flex align-items-center"
              />

              <hs-group
                v-if="question.isEnableComment"
                label-for="title"
                label-class="font-weight-bold"
                :label="$t(`${prefix}.questions.comments.label`)"
                class="mt-3"
              >
                <Editor v-model="question.comment" :initial-value="question.comment || ''" :init="editorDefaultInit" />
              </hs-group>
            </div>
          </b-collapse>
        </div>
      </div>

      <div class="d-flex flex-row-reverse">
        <MXButton id="add-question" @click="addQuestion" variant="primary" type="button">{{
          $t(`${prefix}.questions.new`)
        }}</MXButton>
      </div>

      <hsConfirmModal
        id="delete-question-modal"
        icon="trash-alt"
        variant="cherry"
        hide-header
        :customTitle="$t(`${prefix}.questions.confirm_modal.question.title`)"
        :description="$t(`${prefix}.questions.confirm_modal.question.description`)"
        :ok-title="$t(`${prefix}.questions.confirm_modal.ok`)"
        :cancel-title="$t(`${prefix}.questions.confirm_modal.cancel`)"
        @ok="onDeleteQuestion"
      />

      <hsConfirmModal
        id="delete-question-answer-modal"
        icon="trash-alt"
        variant="cherry"
        hide-header
        :customTitle="$t(`${prefix}.questions.confirm_modal.answer.title`)"
        :description="$t(`${prefix}.questions.confirm_modal.answer.description`)"
        :ok-title="$t(`${prefix}.questions.confirm_modal.ok`)"
        :cancel-title="$t(`${prefix}.questions.confirm_modal.cancel`)"
        @ok="onDeleteQuestionAnswer"
      />
    </div>
  </div>
</template>

<script>
import { hsConfirmModal } from '@/components';
import toastHelper from '@/shared/helpers/toast';
import { ActivityService } from '@/services';
import Editor from '@tinymce/tinymce-vue';
import { editorDefaultInit } from '@/libs/tinymce';
import RadioInputBox from '@/shared/components/RadioInputBox.vue';
import MInput from '@/components/MInput.vue';
import MXButton from '@/shared/components/MXButton.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import MXMultiSelect from '@/shared/components/MXMultiSelect.vue';

export default {
  name: 'Question',
  components: {
    hsConfirmModal,
    Editor,
    RadioInputBox,
    MInput,
    MXButton,
    MSwitch,
    MXMultiSelect,
  },
  data() {
    return {
      question: null,
      answer: null,
      componentKey: 0,
      renderComponent: true,
      infoAlert: true,
      editorDefaultInit: { ...editorDefaultInit },
    };
  },
  computed: {
    prefix() {
      return 'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor';
    },
    questionTypes() {
      return [
        // 'DiscursiveExamQuestion',
        'MultipleChoiceExamQuestion',
        'AssociativeExamQuestion',
        'TrueOrFalseExamQuestion',
      ];
    },
    multipleChoiceExamQuestion: function() {
      return {
        correct: false,
        title: '',
      };
    },
    associativeExamQuestion: function() {
      return {
        first_assertion_attributes: {
          text: '',
        },
        second_assertion_attributes: {
          text: '',
        },
      };
    },
    trueOrFalseExamQuestion: function() {
      return {
        correct: true,
        title: 'Resposta',
      };
    },
  },
  props: {
    activity: {
      type: Object,
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    customLabel(params) {
      const label = {
        MultipleChoiceExamQuestion: this.$t(`${this.prefix}.questions.types.multiple_choice_exam_question`),
        AssociativeExamQuestion: this.$t(`${this.prefix}.questions.types.associative_exam_question`),
        TrueOrFalseExamQuestion: this.$t(`${this.prefix}.questions.types.true_or_false_exam_question`),
      };
      return label[params];
    },
    addAnswer(index, type, action = 'update') {
      const content = {
        MultipleChoiceExamQuestion: { ...this.multipleChoiceExamQuestion },
        AssociativeExamQuestion: {
          first_assertion_attributes: {
            text: '',
          },
          second_assertion_attributes: {
            text: '',
          },
        },
        TrueOrFalseExamQuestion: { ...this.trueOrFalseExamQuestion },
      };
      if (action === 'update') {
        if (this.activity.exam_questions[index].multiple_choice_options.length === 5) {
          toastHelper.dangerMessage(this.$t(`${this.prefix}.questions.errors.max_questions`));
          return;
        }
        this.activity.exam_questions[index].multiple_choice_options.push(content[type]);
      } else {
        this.activity.exam_questions[index].multiple_choice_options = [content[type]];
      }
      this.forceRerender();
    },
    addQuestion() {
      this.activity.exam_questions.push({
        type: null,
        options_attributes: {},
        point: 0,
        title: '',
        comment: '',
        isEnableComment: false,
      });
    },
    deleteQuestion(question) {
      this.question = question;
      this.$bvModal.show('delete-question-modal');
    },
    deleteAnswer(question, answer) {
      this.question = question;
      this.answer = answer;
      this.$bvModal.show('delete-question-answer-modal');
    },
    async onDeleteQuestionAnswer() {
      try {
        this.question.id &&
          this.answer.id &&
          (await ActivityService.deleteQuestionAnswer({ questionId: this.question.id, answerId: this.answer.id }));
        const questionIndex = this.activity.exam_questions.findIndex(item => item == this.question);
        this.activity.exam_questions[questionIndex].multiple_choice_options = this.activity.exam_questions[
          questionIndex
        ].multiple_choice_options.filter(item => item !== this.answer);
        this.forceRerender();
      } catch (error) {
        toastHelper.dangerMessage(error);
      }
    },
    async onDeleteQuestion() {
      try {
        this.question.id && (await ActivityService.deleteQuestion(this.question.id));
        this.activity.exam_questions = this.activity.exam_questions.filter(item => item !== this.question);
      } catch (error) {
        toastHelper.dangerMessage(error);
      }
    },
  },
  mounted() {
    this.activity.exam_questions = this.activity.exam_questions.map(item => {
      if (item.type === 'AssociativeExamQuestion') {
        item.multiple_choice_options = item.associative_options.map(option => {
          return {
            id: option.id,
            first_assertion_attributes: {
              id: option.first_assertion.id,
              text: option.first_assertion.text,
            },
            second_assertion_attributes: {
              id: option.second_assertion.id,
              text: option.second_assertion.text,
            },
          };
        });
      }
      let question = {
        isEnableComment: !!item.comment,
        ...item,
      };
      if (item.type === 'MultipleChoiceExamQuestion') {
        item.multiple_choice_options.forEach((choice, index) => {
          if (choice.correct) question.correct = index;
          choice.correct = false;
        });
      }
      return question;
    });
  },
};
</script>

<style lang="scss" scoped>
/deep/.trash-icon {
  color: #c12b4b;
}
.sub-title-question {
  color: #9a9a9a;
}

/deep/.alert {
  background-color: #ead3fe;
  border-left: #7427f1 2px solid !important;

  button > i {
    color: #7427f1 !important;
  }

  div > i {
    margin-top: 3px;
    color: #7427f1 !important;
  }
}

/deep/ .form-control {
  border: 1px solid #bababa;
  border-radius: 4px;
  transition: border-color 0.25s ease, box-shadow 0.25s ease;

  &:focus {
    outline: #7427f1 solid 1px !important;
    border-color: #7427f1;
    box-shadow: 0 0 0 px #fff, 0 0 0 2px rgba(116, 39, 241, 1);
  }
}
</style>
